<template>
  <div class="error-msg" v-if="isLoading">
    <div class="line-header bg-main text-center">
      <div class="empty-title rounded-circle shadow-sm border">
        <span v-if="shop_line_name">{{ shop_line_name.substring(0, 1) }}</span>
      </div>
    </div>
    <div class="container mt-5 pt-5">
      <h3 class="text-center">{{ shop_line_name }}</h3>
      <p class="text-center py-3 text-muted">
        您尚未加入官方帳號，
        <br />請點選加入好友，關注最新優惠資訊
      </p>
      <div class="form-group my-4 d-flex">
        <div class="col px-1">
          <a class="btn btn-block btn-main" :href="'https://line.me/R/ti/p/' + shop_line_id">加入好友</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { inject } from "vue";

export default {
  name: "LineAddFriend",
  components: {},
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  emits: ["onLoading"],
  setup(props, { emit }) {
    const shop_name = inject("shop_name");
    const shop_logo = inject("shop_logo");
    const shop_line_name = inject("shop_line_name");
    const shop_line_id = inject("shop_line_id");

    return { shop_name, shop_logo, shop_line_name, shop_line_id };
  },
};
</script>
